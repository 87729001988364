export default function MenuFunc() {

	



	const closeButton = document.querySelector(".close_menu")
	const menu_left = document.querySelector("#meuHover")
	const icon_close = document.querySelector(".icon_close")
	const icon_close_hover = document.querySelector(".icon_close_hover")



	//window.introHome1.pause(0, false);

	let menuTogle = document.querySelector(".menu-togle")


	menuTogle?.addEventListener("click", () => {

		console.log("Teste aqui")

		menu_left.classList.add('slide-in-menu')

		const styleElement = document.createElement('style');

		

	})

	let menuTogleBlack = document.querySelector(".menu-togle_black")


	menuTogleBlack?.addEventListener("click", () => {

		console.log("Teste aqui")

		menu_left.classList.add('slide-in-menu')

		const styleElement = document.createElement('style');

		

	})



	

	closeButton?.addEventListener('click', () => {
		menu_left.classList.remove('slide-in-menu')

		const styleElement = document.createElement('style');

		// styleElement.textContent = `
		 
		//  .hideMenu {
		// 	opacity: 1;
		// 	filter: none;
		// }
		// 	`

		// document.body.appendChild(styleElement);
	})


}