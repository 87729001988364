import Swiper from "swiper"
import { find,findAll } from "utils/dom"

export default function () {
	const Home = find("[js-page='home']")
	if (!Home) return


	


	const destinos_depoimentos = findAll('.js-card-carrosel')

	

    destinos_depoimentos.forEach(item => {
		item.addEventListener('click', () => {

			destinos_depoimentos.forEach(otherItem => {
				otherItem.classList.remove('active-slide');
			});
		
			// Adiciona a classe 'active-slide' ao item clicado
			item.classList.add('active-slide');

			
			
		

				
			
		})
						   
    });



	


	const swiperLocalHome = new Swiper(".swiperLocalHome", {
		slidesPerView: 'auto',
		preloadImages: false,
				
		loop: true,
		spaceBetween: 16,
		watchSlidesProgress: true,
		grabCursor: true,
		slideToClickedSlide:true,
		centeredSlides:false,

		
		centeredSlidesBounds:true,


				allowTouchMove: true,
				allowTouch: true,
				
				breakpoints: {
					220: {
						speed: 0,
					},

					1024: {
						speed: 3000,
		
					},	
				},

				


				autoplay: {
					delay: 1,
					pauseOnMouseEnter: true,
					disableOnInteraction: false // Set this to false
				},



		





		
		
		

		
		
				
		

		pagination: {
			el: ".swiperLocalHome .swiper-pagination",
			clickable: true,
			type: "bullets",
		},

		navigation: {
			nextEl: '.swiperLocalHome #scrollRightBtn',
			prevEl: '.swiperLocalHome #scrollLeftBtn',
		},

		on: {
			// Pause autoplay when clicked
			click: function () {
				this.autoplay.stop();
			},
	
			// Resume autoplay when mouse is released
			mousewheel: function () {
				this.autoplay.start();
			}
		}
	
	

	});


	const swiperInstagran = new Swiper(".swiperInstagran", {
		slidesPerView: 'auto',
		preloadImages: false,
				
		spaceBetween: 12,
		watchSlidesProgress: true,
		grabCursor: true,
		slideToClickedSlide:true,
		centeredSlides:true,

		
		centeredSlidesBounds:true,


		loop: true,
		allowTouchMove: true,
		allowTouch: true,
		speed: 3000,
		autoplay: {
			delay: 1,
			pauseOnMouseEnter: true,
			disableOnInteraction: false 
		},


		
			
		
				
		breakpoints: {
					
			1024: {
				slidesPerView: 'auto',
				
				spaceBetween: 16,
				centeredSlides: false,
				

			},	

				

		},

		pagination: {
			el: ".swiper-pagination-insta",
			clickable: true,
			type: "fraction",
		},

		navigation: {
			nextEl: '.swiperInstagran #scrollRightBtn',
			prevEl: '.swiperInstagran #scrollLeftBtn',
		},

		on: {
			// Pause autoplay when clicked
			click: function () {
				this.autoplay.stop();
			},
	
			// Resume autoplay when mouse is released
			mousewheel: function () {
				this.autoplay.start();
			}
		},
	

	});



}