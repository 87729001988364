// import { gsap } from "gsap";
// import { height } from "../utils/dom";

// export default function animateFrom(elem, direction) {

// 	direction = direction | 1;
// 	let delay = 0

// 	let x = 0,
// 		y = 0,
// 		scale = 1,
// 		skewYY = 0,
// 		ease = "power3",
// 		opacity = 1

// 	let duration = 1
// 	const att = elem.getAttribute('data-anima')
// 	const valorTransicao = elem.getAttribute("data-value")


// 	//direção
// 	if (att === "left") {
// 		x = valorTransicao || -100;
// 		y = 0;
// 	} else if (att === "right") {
// 		x = valorTransicao || 100;
// 		y = 0;
// 	} else if (att === "down") {
// 		y = valorTransicao || height(elem);

// 	} else if (att === "up") {
// 		y = - (valorTransicao || height(elem));

// 	}
// 	else if (att === "scale") {
// 		scale = 0.6;

// 	}
// 	else if (att === "opacity") {
// 		opacity = 0;

// 	}

// 	if (elem.hasAttribute('skewy')) {
// 		skewYY = 5
// 	}
// 	//delay
// 	if (elem.dataset.delay) {
// 		delay = elem.dataset.delay.includes('.') ? `${elem.dataset.delay}` : `.${elem.dataset.delay}`
// 	}
// 	if (elem.dataset.duration) {
// 		duration = `${elem.dataset.duration}`
// 	}
// 	if (elem.dataset.ease) {
// 		ease = `${elem.dataset.ease}`
// 	}



// 	gsap.timeline()
// 		.fromTo(elem, {
// 			x: x,
// 			y: y,
// 			scale: scale,
// 			skewY: skewYY,
// 			autoAlpha: opacity

// 		}, {
// 			// ease: CustomEase.get("easeSuave"),
// 			ease: ease,
// 			duration: duration,
// 			x: 0,
// 			y: 0,
// 			autoAlpha: 1,
// 			delay: delay,
// 			scale: 1,
// 			overwrite: "auto",
// 			skewY: 0,

// 			onComplete: () => elem.classList.add("animou"),
// 			// onStart: () => elem.closest('section').classList.add("animate-enter")
// 		}).timeScale(1)

// }

//-----------------------------


// import { gsap } from 'gsap'
// import { height } from '../utils/dom'

// export default function animateFrom(elem, direction) {
// 	direction = direction | 1
// 	let delay = 0

// 	let x = 0,
// 		y = 0,
// 		scale = 1,
// 		skewYY = 0,
// 		ease = 'power3',
// 		opacity = 1

// 	let duration = 1
// 	const att = elem?.getAttribute('data-anima')
// 	const valorTransicao = elem?.getAttribute('data-value')

// 	//direção
// 	if (att === 'left') {
// 		x = valorTransicao || -100
// 		y = 0
// 		opacity = 0
// 	} else if (att === 'right') {
// 		x = valorTransicao || 100
// 		y = 0
// 		opacity = 0
// 	} else if (att === 'down') {
// 		y = valorTransicao || height(elem)
// 		opacity = 0
// 	} else if (att === 'up') {
// 		y = -(valorTransicao || height(elem))
		
// 	} else if (att === 'scale') {
// 		scale = 0.6
// 	} else if (att === 'opacity') {
// 		opacity = 0
// 	}

// 	else if (att === 'custom-down') {
// 		y = 150;
// 		opacity = 0;
// 		ease = 'up.out'
// 	} else if (att == 'custom-rotation') {
// 		rotation = 90
// 	} else if (att == 'custom-right') {
// 		x = valorTransicao || 100;
// 		y = 0;
// 		opacity = 0;
// 		ease = 'up.out'
// 	}

// 	if (elem?.hasAttribute('skewy')) {
// 		skewYY = 5
// 	}

	
// 	//delay
// 	if (elem.dataset.delay) {
// 		delay = elem.dataset.delay.includes('.') ? `${elem.dataset.delay}` : `.${elem.dataset.delay}`
// 	}
// 	if (elem.dataset.duration) {
// 		duration = `${elem.dataset.duration}`
// 	}
// 	if (elem.dataset.ease) {
// 		ease = `${elem.dataset.ease}`
// 	}

// 	gsap
// 		.timeline()
// 		.fromTo(
// 			elem,
// 			{
// 				x: x,
// 				y: y,
// 				scale: scale,
// 				skewY: skewYY,
// 				autoAlpha: opacity,
// 			},
// 			{
// 				// ease: CustomEase.get("easeSuave"),
// 				ease: ease,
// 				duration: duration,
// 				x: 0,
// 				y: 0,
// 				autoAlpha: 1,
// 				delay: delay,
// 				scale: 1,
// 				overwrite: 'auto',
// 				skewY: 0,

// 				onComplete: () => elem.classList.add('animou'),
// 				// onStart: () => elem.closest('section').classList.add("animate-enter")
// 			}
// 		)
// 		.timeScale(1)




// }


//---------------------


import { gsap } from 'gsap'
import { height } from '../utils/dom'

export default function animateFrom(elem, direction) {
    direction = direction | 1
    let delay = 0

    let x = 0,
        y = 0,
        scale = 1,
        skewYY = 0,
        ease = 'power3',
        opacity = 1

    let duration = 1
    const att = elem?.getAttribute('data-anima')
    const valorTransicao = elem?.getAttribute('data-value')

    //direção
    if (att === 'left') {
        x = valorTransicao || -100
        y = 0
        opacity = 0
    } else if (att === 'right') {
        x = valorTransicao || 100
        y = 0
        opacity = 0
    } else if (att === 'down') {
        y = valorTransicao || height(elem)
        opacity = 0
    } else if (att === 'up') {
        y = -(valorTransicao || height(elem))
    } else if (att === 'scale') {
        scale = 0.6
    } else if (att === 'opacity') {
        opacity = 0
    } else if (att === 'custom-down') {
        y = 150
        opacity = 0
        ease = 'up.out'
    } else if (att == 'custom-rotation') {
        rotation = 90
    } else if (att == 'custom-right') {
        x = valorTransicao || 100
        y = 0
        opacity = 0
        ease = 'up.out'
    } else if (att === 'up-repeat') {
        y = -(valorTransicao || height(elem))
    }

    if (elem?.hasAttribute('skewy')) {
        skewYY = 5
    }

    //delay
    if (elem.dataset.delay) {
        delay = elem.dataset.delay.includes('.') ? `${elem.dataset.delay}` : `.${elem.dataset.delay}`
    }
    if (elem.dataset.duration) {
        duration = `${elem.dataset.duration}`
    }
    if (elem.dataset.ease) {
        ease = `${elem.dataset.ease}`
    }

    const timelineConfig = {
        x: x,
        y: y,
        scale: scale,
        skewY: skewYY,
        autoAlpha: opacity,
    }

    const timelineOptions = {
        ease: ease,
        duration: duration,
        x: 0,
        y: 0,
        autoAlpha: 1,
        delay: delay,
        scale: 1,
        overwrite: 'auto',
        skewY: 0,
        onComplete: () => elem.classList.add('animou'),
    }

    if (att === 'up-repeat') {
        gsap.timeline({ repeat: -1, yoyo: true }).fromTo(elem, timelineConfig, timelineOptions).timeScale(1)
    } else {
        gsap.timeline().fromTo(elem, timelineConfig, timelineOptions).timeScale(1)
    }
}

// Seleciona todos os elementos e aplica a animação
document.querySelectorAll('[js-anima]').forEach(elem => {
    animateFrom(elem)
})




// export function animationContainerImag() {
// 	gsap.registerPlugin(ScrollTrigger);

// 	gsap.to(".container", {
// 		xPercent: -200,
// 		ease: "none",
// 		scrollTrigger: {
// 			trigger: ".container",
// 			pin: true,
// 			scrub: 1,
// 			end: "+=3000"
// 		}
// 	});

// }
