import { gsap } from "gsap";
import { findAll } from "../utils/dom.js";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import animateFrom from "./animateFrom.js";


export default function animaAoScroll() {
	const start = "top bottom+=100px"

	findAll("[js-anima]")
		.forEach(function (elem) {

			ScrollTrigger.create({
				trigger: elem,
				once: true,
				//markers: true,
				// start: start,
				 start: "top 1000",
				refreshPriority: 10,
				//markers: true,
				onEnter: function () {
					animateFrom(elem)

				},
				// onEnterBack: function () {
				// 	animateFrom(elem, -1)
				// },
				// onLeave: function () {
				// 	hide(elem)
				// } // assure that the element is hidden when scrolled into view


			});
		});


		gsap.registerPlugin(ScrollTrigger);

		gsap.to(".container_teste", {
		xPercent: -90,
		ease: "power1.inOut", // Suaviza a animação
		scrollTrigger: {
			trigger: ".container_teste",
			pin: true,
			scrub: 10, // Ajuste para suavizar a transição
			end: "+=900",
			//markers: true 
		}
		});


}