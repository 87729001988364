

import axios from 'axios'
import axiosConfig from './modules/axiosConfig'
import PageHome from './pages/Home.js'
import PageLogin from "./pages/Login";
import UseMouseOrKeyboard from "./modules/UseMouseOrKeyboard";
import DesignSystem from "./DesignSystem/DesignSystem";
import { register } from 'swiper/element/bundle';
import logs from "./utils/logs";
import ripple from "./modules/ripple";
import formErros from "./modules/formErros";
import animaAoScroll from "./animation/animaAoScroll";
import animateSetup from "./animation/animateSetup";
import Swiper from "swiper"
import MenuFunc from "./modules/menu";
import mottaEquipeText from "./modules/converteditorjs";
import contatoForm from "./modules/contato-conectese";
import contatoFormMobile from "./modules/contato-conectese-mobile";
// import SvgUse from "./utils/SvgUse";



// import SvgUse from "./utils/SvgUse";
import instagramApi from './modules/instagramApi';
import instagramCodigoBase from './modules/instagramBase'
// Init





// Init
GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component

// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
UseMouseOrKeyboard()
ripple()
formErros()
animateSetup()
animaAoScroll()
contatoForm()
contatoFormMobile()

// Pages
PageHome()
MenuFunc()
PageLogin()?.init()
mottaEquipeText()

// utils logs
logs()

const swiperBanner = new Swiper(".swiper-banner", {
    slidesPerView: 1,
    effect: "fade",
    loop:true,
    speed: 1200,
    autoplay: {
        delay: 3000,
    }, 

    pagination: {
        el: '.swiper-pagination',
        clickable: 'true',
        type: 'bullets',
        renderBullet: function (index, className) {
            return '<span class="' + className + '">' + '<em>'+ '</em>' + '<i></i>' + '<b></b>'  + '</span>';
          },
    
    },
    
});

const swiperTextUnnec = new Swiper(".swiperTextUnnec", {
    slidesPerView: 'auto',
    preloadImages: false,
            
    
    spaceBetween: 12,
    watchSlidesProgress: true,
    grabCursor: true,
    slideToClickedSlide:true,
    centeredSlides:true,

    
    centeredSlidesBounds:true,

    pagination: {
        el: ".swiperTextUnnec .swiper-pagination",
        clickable: true,
        type: "bullets"
      },
    
            
    breakpoints: {

        220: {
            slidesPerView: "auto",
            
            spaceBetween: 0,
            centeredSlides: false,
            

        },	
    
        1024: {
            slidesPerView: 'auto',
            
            spaceBetween: 0,
            centeredSlides: false,
            

        },	

            

    },


});

const swiperTitulo = new Swiper(".swiperTitulo", {
    
    slidesPerView: 'auto',
		preloadImages: false,
				
		loop: true,
		spaceBetween: 16,
		watchSlidesProgress: true,
		grabCursor: true,
		slideToClickedSlide:true,
		centeredSlides:false,

		
		centeredSlidesBounds:true,


				allowTouchMove: true,
				allowTouch: true,
				
				breakpoints: {
					220: {
						speed: 0,
					},

					1024: {
						speed: 3000,
		
					},	
				},

				


				autoplay: {
					delay: 1,
					pauseOnMouseEnter: true,
					disableOnInteraction: false // Set this to false
				},

    pagination: {
        el: ".swiperTitulo .swiper-pagination",
        clickable: true,
        type: "bullets",
    },

    navigation: {
        nextEl: '.swiperTitulo #scrollRightBtn',
        prevEl: '.swiperTitulo #scrollLeftBtn',
    },



});



let textPt = document.querySelectorAll(".text-pt");
let textEn = document.querySelectorAll(".text-en");
let textEs = document.querySelectorAll(".text-es");


document.addEventListener('DOMContentLoaded', function () {
const text_select_pt = document.querySelector('.text_select_pt');
const text_select_en = document.querySelector('.text_select_en');
const text_select_es = document.querySelector('.text_select_es');



text_select_pt.addEventListener("click", () => switchLanguage('pt'));
text_select_en.addEventListener("click", () => switchLanguage('en'));
text_select_es.addEventListener("click", () => switchLanguage('es'));

// Carrega o idioma salvo (opcional)
const storedLanguage = localStorage.getItem('selectedLanguage');
if (storedLanguage) {
  switchLanguage(storedLanguage);
}
});

function switchLanguage(lang) {
if (lang === 'pt') {
  document.documentElement.classList.add('lang-pt');
  document.documentElement.classList.remove('lang-en', 'lang-es');
  switchText(textPt, textEn, textEs);
} else if (lang === 'en') {
  document.documentElement.classList.add('lang-en');
  document.documentElement.classList.remove('lang-pt', 'lang-es');
  switchText(textEn, textPt, textEs);
} else if (lang === 'es') {
  document.documentElement.classList.add('lang-es');
  document.documentElement.classList.remove('lang-pt', 'lang-en');
  switchText(textEs, textPt, textEn);
}
// Salva o idioma no localStorage
localStorage.setItem('selectedLanguage', lang);
}

function switchText(showText, hideText1, hideText2) {
showText.forEach(element => element.classList.remove('hidden'));
hideText1.forEach(element => element.classList.add('hidden'));
hideText2.forEach(element => element.classList.add('hidden'));
}



//DESKTOP
const swiperEquipeHome = new Swiper(".swiperEquipeHome", {
    slidesPerView: 'auto',
    preloadImages: false,
    
    spaceBetween: 32,
    watchSlidesProgress: true,
    grabCursor: true,
    //slideToClickedSlide:true,
    centeredSlides:true,

    
    centeredSlidesBounds:true,

      
    pagination: {
        el: ".swiper-pagination-especializada",
        clickable: 'true',
        type: 'bullets',
        
      },
    
            
    breakpoints: {

        220: {
            slidesPerView: "auto",
            slideToClickedSlide:false,
            spaceBetween: 16,
            centeredSlides: true,
            slidesPerGroup: 1,
        },

        720: {
            slidesPerView: 'auto',

            spaceBetween: 32,
            centeredSlides: false,


        },
                
        /*1024: {
            slidesPerView: 'auto',
            
            spaceBetween: 32,
            centeredSlides: false,
            

        },*/
        
        1025: {
            slidesPerView: 5.2,

            spaceBetween: 32,
            centeredSlides: false,


        },
    },

    navigation: {
        nextEl: '#scrollRightBtn_swiperEquipeHome', 
        prevEl: '#scrollLeftBtn_swiperEquipeHome', 
    },
    


});


//MOBILE
const swiperEquipeHome_mobile = new Swiper(".swiperEquipeHome_mobile", {
    slidesPerView: 'auto',
    preloadImages: false,
     
    
    spaceBetween: 32,
    watchSlidesProgress: true,
    grabCursor: true,
    //slideToClickedSlide:true,
    centeredSlides:true,
    
    centeredSlidesBounds:true,


            
    breakpoints: {
                
        1024: {
            slidesPerView: 'auto',
            
            spaceBetween: 32,
            centeredSlides: false,
            

        },	
     

    },

});



const swiperScroll = new Swiper(".swiperScroll", {
    slidesPerView: 1,
    preloadImages: false,
    spaceBetween: 24,
    slideToClickedSlide:true,
    centeredSlides:true,

    
    

    

      
    pagination: {
        el: ".swiperScroll .swiper-pagination",
        clickable: 'true',
        type: 'bullets',
        
    },
    
            
   

    

    navigation: {
        nextEl: '.swiperScroll #scrollRightBtn', 
        prevEl: '.swiperScroll #scrollLeftBtn', 
    },
    


});


swiperEquipeHome.controller.control = swiperEquipeHome_mobile;
swiperEquipeHome_mobile.controller.control = swiperEquipeHome;




const swiperLocalAbout = new Swiper(".swiperLocalAbout", {
    slidesPerView: 'auto',
    preloadImages: false,
            
    loop: true,
    spaceBetween: 16,
    watchSlidesProgress: true,
    grabCursor: true,
    slideToClickedSlide:true,
    centeredSlides:false,

    
    centeredSlidesBounds:true,


            allowTouchMove: true,
            allowTouch: true,
            
            breakpoints: {
                220: {
                    speed: 0,
                },

                1024: {
                    speed: 6000,
    
                },	
            },

            


            autoplay: {
                delay: 1,
                pauseOnMouseEnter: true,
                disableOnInteraction: false // Set this to false
            },



    





    
    
    

    
    
            
    

    pagination: {
        el: ".swiperLocalAbout .swiper-pagination",
        clickable: true,
        type: "bullets",
    },

    navigation: {
        nextEl: '.swiperLocalAbout #scrollRightBtn',
        prevEl: '.swiperLocalAbout #scrollLeftBtn',
    },

    on: {
        // Pause autoplay when clicked
        click: function () {
            this.autoplay.stop();
        },

        // Resume autoplay when mouse is released
        mousewheel: function () {
            this.autoplay.start();
        }
    }



});



instagramCodigoBase()

instagramApi()










